<template>
    <div class="">
      <HomeReportVue v-if="page == 'home'" @changePage="changePage" />
      <PurchaseVue class="" v-if="page == 'purchases'" @mtdBack="mtdBack" />
      <DispatchVue class="" v-if="page == 'dispatch'" @mtdBack="mtdBack" />
      <template v-if="$store.getters.get_rol.id == 1">
        <SalesAdmin class="" v-if="page == 'sales'" @mtdBack="mtdBack"/>
      </template>
      <template v-else>
        <SalesVue class="" v-if="page == 'sales'" @mtdBack="mtdBack"/>
      </template>
      <StockVue class="" v-if="page == 'stock'" @mtdBack="mtdBack"/>
      <template v-if="$store.getters.get_rol.id == 1">
        <FlujoRepartidorAdmin class="" v-if="page == 'flujo-repartidor'" @mtdBack="mtdBack"/>
      </template>
      <template v-else>
        <FlujoRepartidor class="" v-if="page == 'flujo-r7epartidor'" @mtdBack="mtdBack"/>
      </template>
    </div>
  </template>
  
  <script>
  import HomeReportVue from "../../components/report/Home.vue";
  import PurchaseVue from "../../components/report/Purchase.vue"
  import DispatchVue from "../../components/report/Dispatch.vue";
  import SalesVue from "../../components/report/Sales.vue";
  import StockVue from "../../components/report/Stock.vue";
  import FlujoRepartidor from "../../components/report/FlujoRepartidor.vue";
  import SalesAdmin from "../../components/report/SalesAdmin.vue";
  import FlujoRepartidorAdmin from "../../components/report/FlujoRepartidorAdmin.vue";
  
  
  export default {
    components: {
      HomeReportVue,
      PurchaseVue,
      DispatchVue,
      SalesVue,
      StockVue,
      FlujoRepartidor,
      SalesAdmin,
      FlujoRepartidorAdmin
    },
    data() {
      return {
        page: "home",
      };
    },
    methods: {
      changePage: function (page) {
        this.page = page;
        // console.log(this.page);
        this.$emit("mtdViewFooter", false, "report");
      },
      mtdBack: function () {
        this.page = "home";
        // console.log(this.page);
        this.$emit("mtdViewFooter", true, "report");
      },
    },
  };
  </script>
  