<template>
    <div>
      <CRow class="">
        <CCol lg="4" xl="4" md="12" sm="12" xs="12">
          <CCard>
            <CCardHeader class="d-flex justify-content-between">
              <span
                ><i class="fas fa-money-bill"></i>&nbsp; REPORTE DE VENTAS</span
              >
              <button
                @click="mtdBack"
                type="button"
                class="btn btn-sm text-white"
                style="background: #926cdc"
              >
                <i class="fas fa-hand-point-left"></i> Retroceder
              </button>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs="12" sm="12" md="6" lg="6">
                  <CInput
                    label="Fecha Ini."
                    type="date"
                    v-model="rp_sales.f_inicio"
                    @change="form_selected"
                  />
                </CCol>
                <CCol xs="12" sm="12" md="6" lg="6">
                  <CInput
                    label="Fecha Fin."
                    type="date"
                    v-model="rp_sales.f_final"
                    @change="form_selected"
                  />
                </CCol>
                <CCol xs="12" sm="12" md="12" lg="12">
                    <select class="form-control" v-model="rp_sales.dealer">
                        <option value="0">[Todos los repartidores..]</option>
                        <option v-for="(item, index) in dealers" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12" sm="12" md="12" lg="12" class="mt-2">
                  <button
                    type="button"
                    style="background: #926cdc"
                    :class="btnClasses"
                    @click="loadTable"
                  >
                    <i class="fas fa-search"></i>&nbsp; Buscar
                  </button>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol v-if="vue.exp" lg="8" xl="8" md="12" sm="12" xs="12">
          <CTableWrapper
            :items="data"
            :fields="fields"
            hover
            :striped="true"
            :border="true"
            small
            fixed
            caption="Reporte de ventas"
            icon="fas fa-money-bill"
            btn_name="Usuario"
            :size="'md'"
            :button_new="false"
            :actions="true"
            :withActions="'15%'"
            :buttonEdit="false"
            :buttonDelete="false"
            :buttonBack="false"
            :myButtons="myButtons"
            @mtd_ticket="mtd_ticket"
            @mtd_salesdetail="mtd_salesdetail"
          />
        </CCol>
      </CRow>
      <!--Modal Purchase Detail-->
      <CModalForm
        :backdrop="true"
        :closeOnBackdrop="true"
        :size="'xl'"
        :color="'info'"
        :centered="true"
        :title="'Detalles de Venta'"
        :show_boton="false"
        :show.sync="modal_salesdetail.boo"
      >
        <div v-for="(report, index) in report_sales" :key="index">
          <CRow>
            <CCol lg="12" xl="12" md="12" sm="12" xs="12">
              <CCard class="mb-0">
                <CButton
                  block
                  color="link"
                  class="text-left shadow-none card-header"
                  @click="accordion = accordion === index ? false : index"
                >
                  <h5 class="m-0">{{ report.product }}</h5>
                </CButton>
                <CCollapse :show="accordion === index">
                  <CCardBody>
                    <CRow>
                      <CCol lg="12" xl="12" md="12" sm="12" xs="12">
                        <CCard>
                          <CCardBody v-for="(item, ind) in report.details" :key="ind">
                            <h5>{{ item.type }}</h5>
                            <CRow>
                              <CCol xs="12" sm="12" md="6" lg="6">
                                <CInput
                                  label="Cantidad"
                                  placeholder="0"
                                  v-model="item.quantity"
                                  :disabled="true"
                                />
                              </CCol>
                              <CCol xs="12" sm="12" md="6" lg="6">
                                <CInput
                                  label="Precio de Venta"
                                  placeholder="0"
                                  v-model="item.price"
                                  :disabled="true"
                                />
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
          </CRow>
        </div>
      </CModalForm>
    </div>
  </template>
  <script>
  const fields = [
    { key: "id", label: "Id", _style: "width:3%" },
    { key: "dispatch", label: "N°. Despacho", _style: "width:3%" },
    { key: "dealer", label: "Repartidor", _style: "width:3%" },
    // { key: "num_ope", label: "N°. Operación", _style: "width:3%" },
    { key: "warehouses", label: "Local", _style: "width:3%" },
    { key: "client", label: "Cliente", _style: "width:15%;" },
    // { key: "metodo", label: "Met. Pago", _style: "width:13%;" },
    { key: "campus", label: "Sede", _style: "width:5%;" },
    { key: "date", label: "Fecha", _style: "width:5%;" },
    { key: "amortizacion", label: "Amortizacion", _style: "width:5%;" },
    { key: "devolution", label: "Devolución", _style: "width:5%;" },
    { key: "total", label: "Cantidad", _style: "width:8%;" },
    { key: "payment", label: "Estado", _style: "width:8%;" },
  ];
  
  import CTableWrapper from "../../components/shared/datatable/Table.vue";
  import CModalForm from "../../components/shared/modals/cModalForm.vue";
  import { mapActions } from "vuex";
  import { bus } from "../../main";
  import moment from "moment";
  
  export default {
    components: {
      CTableWrapper,
      CModalForm,
    },
    data() {
      return {
        prefix: "process",
        vue: {
          exp: false,
          texto: "",
          estado: 1,
        },
        fields,
        data: [],
        report_sales: [],
        myButtons: [
          {
            class: "btn-sm",
            color: "success",
            tooltip: "Ver detalles",
            action: "mtd_salesdetail",
            icon: "fas fa-eye",
          },
          {
            class: "btn-sm",
            color: "primary",
            tooltip: "Ver ticket",
            action: "mtd_ticket",
            icon: "fas fa-file",
          },
        ],
        rp_sales: {
          f_inicio: null,
          f_final: null,
          dealer:0
        },
        modal_salesdetail: {
          boo: false,
        },
        dealers:[],
        // accordion
        collapse: false,
        cardCollapse: true,
        innerCollapse: false,
        accordion: 0,
      };
    },
    computed: {
      btnClasses() {
        return [`btn text-white w-100`];
      },
    },
    created() {
      moment.locale("es");
      this.rp_sales.f_inicio =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
      this.rp_sales.f_final = this.rp_sales.f_inicio;
      // this.mtd_getdata(this.rp_sales.f_inicio, this.rp_sales.f_final);
      this.mtdIinit();
    },
    methods: {
      ...mapActions(["get", "post"]),
      mtdIinit: function (f_inicio, f_final) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/process/salesReportAdmin",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.dealers = response.data.dealers;
          })
          .catch((errors) => {
            // this.errorsBackend = errors;
            // this.$emit("error", this.event);
          });
      },
      mtd_getdata: function (f_inicio, f_final) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/process/" +
            f_inicio +
            "/" +
            f_final +
            "/"+
            this.rp_sales.dealer+
            "/salesReportAdmin",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.data = response.data.sales;
          })
          .catch((errors) => {
            // this.errorsBackend = errors;
            // this.$emit("error", this.event);
          });
      },
      mtdBack: function () {
        this.$emit("mtdBack");
      },
      form_selected: function () {
        this.vue.estado = 0;
      },
      loadTable: function () {
        let f_inicio = this.rp_sales.f_inicio;
        let f_final = this.rp_sales.f_final;
        if (f_inicio && f_final) {
          this.vue.exp = true;
        } else {
          this.vue.exp = false;
        }
        this.mtd_getdata(f_inicio, f_final);
      },
      mtd_salesdetail: function (item) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/process/" +
            item.id +
            "/salesDetailReport",
          token: this.$store.getters.get__token,
        }).then((response) => {
          this.modal_salesdetail.boo = true;
          this.report_sales = response.data.salesDetail;
        });
      },
      mtd_ticket: function (item) {
        window.open(this.$store.getters.get__url + "/ticket/sale/" + item.voucher);
      },
    },
  };
  </script>