<template>
    <div>
      <CRow class="mt-1">
        <CCol lg="12" xl="12" md="12" sm="12" xs="12">
          <CCard>
            <CCardHeader class="d-flex justify-content-between">
              <span><i class="fas fa-dolly"></i>&nbsp; FLUJO DE REPARTIDOR ad</span>
              <button
                @click="mtdBack"
                type="button"
                class="btn btn-sm text-white"
                style="background: #926cdc"
              >
                <i class="fas fa-hand-point-left"></i> Retroceder
              </button>
            </CCardHeader>
            <CCardBody>
              <CRow class="mt-1">
                <CCol sm="12" xs="12" md="3" lg="3" xl="3">
                  <CInput type="date" label="Desde" v-model="filter.f_inicio" />
                </CCol>
                <CCol sm="12" xs="12" md="3" lg="3" xl="3">
                  <CInput type="date" label="Hasta" v-model="filter.f_final" />
                </CCol>
                <CCol v-if="$store.getters.get_rol.id == 1" sm="12" xs="12" md="3" lg="3" xl="3">
                  <label for="">Repartidor</label>
                  <select class="form-control" v-model="filter.dealer">
                    <option value="0">[Todos los repartidores..]</option>
                    <option v-for="(item, index) in dealers" :key="index" :value="item.id">{{ item.name }}</option>
                  </select>
                </CCol>
                <CCol sm="12" xs="12" md="3" lg="3" xl="3">
                  <div style="margin-top: 8px">&nbsp;</div>
                  <button
                    type="button"
                    style="background: #926cdc"
                    :class="btnClasses"
                    @click="loadTable"
                  >
                    <i class="fas fa-search"></i>&nbsp; Buscar
                  </button>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs="12" sm="12" md="6" lg="6">
          <CCard>
            <CCardHeader class="d-flex justify-content-between">
              <span><i class="fas fa-dolly"></i>&nbsp; DESPACHO RECIBIDO</span>
            </CCardHeader>
            <CCardBody>
              <CChartPie
                :datasets="dataDispatch"
                :labels="['VIVO', 'BENEFICIADO']"
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs="12" sm="12" md="6" lg="6">
          <CCard>
            <CCardHeader class="d-flex justify-content-between">
              <span><i class="fas fa-dolly"></i>&nbsp; VENTAS REALIZADAS</span>
            </CCardHeader>
            <CCardBody>
              <CChartBar
                style="height: 300px"
                :datasets="dataSales"
                :labels="['TIPO DE VENTA']"
                :options="{ maintainAspectRatio: false }"
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs="12" sm="12" md="6" lg="6">
          <CCard>
            <CCardHeader class="d-flex justify-content-between">
              <span><i class="fas fa-search-dollar"></i>&nbsp; METODOS DE PAGO</span>
            </CCardHeader>
            <CCardBody>
              <CChartPie :datasets="dataCharge" :labels="labelsCharge" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </template>
    
    <script>
  import { CChartPie, CChartBar } from "@coreui/vue-chartjs";
  import { mapActions } from "vuex";
  import moment from "moment";
  import { bus } from "../../main";
  
  export default {
    components: { CChartPie, CChartBar },
    data() {
      return {
        data: [],
        sales: [],
        charge:[],
        labelsCharge:[],
        filter: {
          f_inicio: null,
          f_final: null,
          dealer:0
        },
        dealers:[]
      };
    },
    computed: {
      btnClasses() {
        return [`btn text-white w-100`];
      },
      dataDispatch() {
        const hasData =
          this.data.total_neto_vivo > 0 || this.data.total_neto_bene > 0;
        return [
          {
            backgroundColor: hasData ? ["#41B883", "#00D8FF"] : ["#CCCCCC"],
            data: hasData
              ? [this.data.total_neto_vivo, this.data.total_neto_bene]
              : [1],
          },
        ];
      },
      dataSales() {
        const hasSalesData =
          this.sales.total_contado > 0 || this.sales.total_credito > 0;
        return [
          {
            label: "CONTADO",
            backgroundColor: hasSalesData ? "#E55353" : "#CCCCCC",
            data: hasSalesData ? [this.sales.total_contado] : [1],
          },
          {
            label: "CRÉDITO",
            backgroundColor: hasSalesData ? "#36A2EB" : "#CCCCCC",
            data: hasSalesData ? [this.sales.total_credito] : [1],
          },
        ];
      },
      dataCharge() {
        const hasData =
          this.charge.EFECTIVO > 0 || this.charge.YAPE > 0 || this.charge.PLIN > 0;
        return [
          {
            backgroundColor: hasData ? ["#41B883", "#249bdb", "#00D8FF"] : ["#CCCCCC"],
            data: hasData
              ? [this.charge.EFECTIVO, this.charge.YAPE,this.charge.PLIN]
              : [1],
          },
        ];
      },
    },
    created() {
      moment.locale("es");
      this.filter.f_inicio =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
      this.filter.f_final = this.filter.f_inicio;
      this.mtdInit();
    },
    methods: {
      ...mapActions(["get", "post"]),
      mtdBack: function () {
        this.$emit("mtdBack");
      },
      mtdInit: function(){
        this.get({
          url:
            this.$store.getters.get__url +
            "/process/flujoAdmin",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.dealers = response.data.dealers;
          })
          .catch((errors) => {});
      },
      loadTable: function () {
        let f_inicio = this.filter.f_inicio;
        let f_final = this.filter.f_final;
        if (f_inicio && f_final) {
          this.mtd_getdata(f_inicio, f_final);
          this.mtd_getdata_2(f_inicio, f_final);
          this.mtd_getdata_3(f_inicio, f_final);
        }
      },
      mtd_getdata: function (f_inicio, f_final) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/process/" +
            f_inicio +
            "/" +
            f_final +
            "/"+ this.filter.dealer+
            "/flujoDispatch",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.data = response.data.dispatch;
            // console.log(this.data);
          })
          .catch((errors) => {});
      },
      mtd_getdata_2: function (f_inicio, f_final) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/process/" +
            f_inicio +
            "/" +
            f_final +
            "/"+this.filter.dealer+
            "/flujoSales",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.sales = response.data.sales;
            // console.log(this.sales);
          })
          .catch((errors) => {});
      },
      mtd_getdata_3: function (f_inicio, f_final) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/process/" +
            f_inicio +
            "/" +
            f_final +
            "/"+this.filter.dealer +
            "/flujoCharge",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.charge = response.data.charge;
            this.labelsCharge = Object.keys(this.charge)
          })
          .catch((errors) => {});
      },
    },
  };
  </script>
    
    <style lang="scss" scoped>
  </style>